import { useState } from 'react';
import { basePageWrap } from '../BasePage';
import Button from '@/components/Button/Button';
import { UserIcon, PlayIcon } from '@heroicons/react/solid';
import { Sticker } from '@/components/Stickers';
import BlockContent from '@/components/BlockContent/BlockContent';
import Modal from '@/components/Modal/Modal';
import MediaPlayer from '@/components/MediaPlayer/MediaPlayer';
import Footer from '@/components/Footer/Footer';

const HomePage = (props) => {
    const [showVideo, setShowVideo] = useState(false);
    const handleClick = (e) => {
        console.log(e);
    };

    return (
        <>
            <div className="p-3 pb-16 md:p-5">
                <div className="max-w-[1440px] mx-auto">
                    {/* Home Hero */}
                    <section className="rounded-2xl bg-[url('/img/bg-weave-mango.svg')] bg-mango bg-[length:220px_auto] p-6 text-center pb-20 md:pb-44 relative mb-5">
                        <Sticker
                            name="peace"
                            color="moss"
                            className="absolute w-[80px] md:w-[150px] top-[15%] left-[2%] md:left-[6%] -rotate-12 z-10"
                        />
                        <Sticker
                            name="sun"
                            color="peach"
                            className="absolute w-[80px] md:w-[150px] top-[90%] md:top-[60%] right-[10%] -rotate-12 z-10 "
                        />
                        <Sticker
                            name="lightning"
                            color="sky"
                            className="absolute w-[80px] md:w-[120px] -top-8 md:-top-4 right-[35%] rotate-12 md:right-[15%] z-10"
                        />

                        <div className="relative z-20">
                            <div className="flex justify-between mb-10">
                                <Button element="a" href="/account/login/" icon={<UserIcon />}>
                                    Kete Log In
                                </Button>
                                <Button element="a" href="/account/sign-up/">
                                    Get Started
                                </Button>
                            </div>
                            <img src="/img/logo-full.svg" className="mx-auto mb-10 w-28 md:w-48" />
                            <h1 className="mb-8 display">{props.title}</h1>
                            <div className="max-w-xl mx-auto text-2xl lg:text-4xl">
                                <p>{props.subtitle}</p>
                            </div>

                            <div className="flex justify-center gap-5 mt-10">
                                <Button element="a" href="/account/sign-up/">
                                    Get Started
                                </Button>
                                {!!props.video && (
                                    <div onClick={() => setShowVideo(true)} className="inline-flex w-max">
                                        <Button icon={<PlayIcon />}>Play Video</Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>

                    <section className="flex flex-col mb-12 block-spacing">
                        <BlockContent blocks={props.body} parentPage="home" />
                    </section>
                </div>
            </div>
            <Footer />
            {!!props.video && <Modal show={showVideo} setShow={setShowVideo} content={<MediaPlayer url={props.video} mode="video" />} />}
        </>
    );
};

HomePage.defaultProps = {};

HomePage.propTypes = {};

export default basePageWrap(HomePage);
