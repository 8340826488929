import { useState, Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

const Modal = ({show, setShow, content}) => {

    let [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
      setIsOpen(show)
    },[show])

    return (
        // Use the `Transition` component at the root level
        <Transition
            show={isOpen}
            enter="transition-all duration-300 fixed inset-0 z-10 w-screen h-screen transform-gpu"
            enterFrom="opacity-0 "
            enterTo="opacity-100"
            leave="transition-all duration-300 fixed inset-0 z-10 w-screen h-screen"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 ">
            <div className="fixed inset-0 z-40 flex items-center justify-center w-screen h-screen bg-black/80">
              <XIcon onClick={() => setShow(false)} className="absolute w-10 h-10 text-white cursor-pointer top-6 right-6" />
              <div className="w-[90%] md:w-[70%] xl:w-[50%] bg-cream animate-fade-in-up">{content}</div>
            </div>
        </Transition>
        
    );
}

export default Modal;
