export default function ({ className }) {
    return (
        <div className={className}>
            <svg viewBox="0 0 178 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9976 33.2668H25.8307L20.4141 28.7285L14.9976 33.2668Z" fill="black" />
                <path d="M5.99468 40.8107L0.530273 45.3898H40.2985L34.8341 40.8107H5.99468Z" fill="black" />
                <path d="M7.76123 39.3289H33.0626L27.5975 34.7498H13.2263L7.76123 39.3289Z" fill="black" />
                <path d="M6.24831 16.8599L0 22.0956V28.0728L9.81514 19.8485L6.24831 16.8599Z" fill="black" />
                <path d="M34.579 16.8591L31.0122 19.8483L40.828 28.0727V22.0949L34.579 16.8591Z" fill="black" />
                <path d="M10.9696 20.8154L0 30.0073V35.9858L14.5365 23.8046L10.9696 20.8154Z" fill="black" />
                <path d="M26.291 23.8046L40.8281 35.9858V30.0073L29.8578 20.8154L26.291 23.8046Z" fill="black" />
                <path d="M15.6916 24.772L0 37.9207V43.8985L19.2584 27.7612L15.6916 24.772Z" fill="black" />
                <path d="M25.1357 22.8371L39.3054 10.9636L35.7385 7.97437L21.5688 19.8479L25.1357 22.8371Z" fill="black" />
                <path d="M1.521 10.964L40.8256 43.8985V37.9206L5.08782 7.9754L1.521 10.964Z" fill="black" />
                <path d="M25.1357 14.9248L34.584 7.0074L31.0166 4.0188L21.5688 11.9355L25.1357 14.9248Z" fill="black" />
                <path d="M23.9816 15.8922L9.81258 4.01935L6.24512 7.00795L20.4148 18.8814L23.9816 15.8922Z" fill="black" />
                <path d="M29.8621 3.0511L26.2953 0.0625L21.5688 4.02309L25.135 7.01169L29.8621 3.0511Z" fill="black" />
                <path d="M23.9799 7.97859L14.5322 0.0625L10.9653 3.0511L20.4131 10.9678L23.9799 7.97859Z" fill="black" />
                <path
                    d="M86.3545 44.7794H70.812L66.0512 26.4832H65.2578V44.7794H51.0688V0.672424H65.3044L65.2578 19.2954H66.0512L70.8587 0.672424H85.7944L79.2133 21.8158L86.3545 44.7794Z"
                    fill="black"
                />
                <path
                    d="M102.269 32.9709H115.571V44.7794H88.0799V0.672424H115.338V12.481H102.269V16.9617H114.591V28.0234H102.269V32.9709Z"
                    fill="black"
                />
                <path d="M148.051 0.672424V12.3876H139.743V44.7794H125.554V12.4343H117.153V0.672424H148.051Z" fill="black" />
                <path
                    d="M164.486 32.9709H177.788V44.7794H150.297V0.672424H177.555V12.481H164.486V16.9617H176.808V28.0234H164.486V32.9709Z"
                    fill="black"
                />
            </svg>
        </div>
    );
}
