import LogoBlack from '@/components/LogoBlack/LogoBlack';
import Button from '@/components/Button/Button';

const year = new Date().getFullYear();
const Footer = ({ className }) => {
    return (
        <footer
            className={`flex flex-col bg-white items-center w-full gap-4 md:gap-8 p-5 mt-auto md:justify-between md:flex-row md:items-center ${className}`}>
            <LogoBlack className="w-32 md:w-42" />
            <div className="flex items-center gap-4 w-max">
                <div>&copy; E Tū Tāngata {year}</div>
                <Button element="a" href="https://www.etutangata.nz" targetBlank type="secondary">
                    www.etutangata.nz
                </Button>
            </div>
        </footer>
    );
};

export default Footer;
